<template>
  <div class="main">
    <div class="custom_warning">
      <!-- 按钮 添加自定义预警 -->
      <div class="add_warning_btn" v-if="warnList.length">
        <i class="el-icon-plus" color="#2a47b0" @click="addWarningConfig"></i>
        <span @click="addWarningConfig">添加自定义预警</span>
      </div>

      <!-- 预警列表 -->
      <template v-if="warnList.length">
        <div class="warning_item" v-for="item in warnList" :key="item.id">
          <!-- 表头 功能 -->
          <div class="title">
            <div class="title_font">{{ item.name }}</div>
            <div class="right_box">
              <div class="edit_box cursor" @click="editWarning(item)">
                <i class="el-icon-edit"></i>
                <span>编辑</span>
              </div>
              <div class="del_box cursor" @click="delPreWarningSystem(item)">
                <i class="el-icon-delete"></i>
                <span>删除</span>
              </div>
              <el-divider direction="vertical"></el-divider>
              <div class="sanjiao cursor" @click="item.expand = !item.expand">
                <i class="el-icon-caret-bottom"></i>
              </div>
            </div>
          </div>
          <div :class="item.expand ? 'content_box' : 'content_box2'">
            <!-- 时间 -->
            <div class="time_box">
              <div class="desc">预警时间</div>
              <div class="content">
                <div class="line">
                  <span v-if="item.weekday & 1">周一</span>
                  <span v-if="item.weekday & 2">周二</span>
                  <span v-if="item.weekday & 4">周三</span>
                  <span v-if="item.weekday & 8">周四</span>
                  <span v-if="item.weekday & 16">周五</span>
                  <span v-if="item.weekday & 32">周六</span>
                  <span v-if="item.weekday & 64">周日</span>
                </div>
                <div class="line">
                  <span>{{ item.time_start }} - {{ item.time_end }}</span>
                </div>
              </div>
            </div>

            <!-- 参数 -->
            <div class="param_box">
              <div class="desc">预警参数</div>
              <div class="content">
                <div class="line" v-for="(ite, idx) in item.warn_array" :key="idx">
                  <div class="item">
                    <span class="type">{{ ite.type | typeFmt }}</span>
                    <div class="range1">小于{{ ite.min }}{{ ite.type | unitFmt }}</div>
                    <div class="range2">大于{{ ite.max }}{{ ite.type | unitFmt }}</div>
                  </div>
                  <div class="item">
                    <span class="type">持续时间</span>
                    <div>范围: {{ ite.time_duration }}min</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 使用系统 -->
            <div class="sys_box">
              <div class="desc">使用系统</div>
              <div class="content">
                <div class="line" v-for="(ite, idx) in item.org_array" :key="idx">
                  <div class="name_project">
                    {{ ite.name_project || "未命名项目" }}
                  </div>
                  <div class="system_box">
                    <div class="name" v-for="(j, k) in ite.device_array" :key="k">
                      {{ j.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 推送方式 -->
            <div class="push_type">
              <div class="desc">推送方式</div>
              <div class="content">
                <div v-if="item.push_type & 1" class="type">短信推送</div>
                <div v-if="item.push_type & 2" class="type">APP内推送</div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- 空状态 -->
      <div class="empty_state" v-else>
        <img src="../../../assets/empty_img.png" alt="" />
        <el-button @click="addWarningConfig" type="primary">添加自定义预警</el-button>
      </div>
    </div>

    <ChangeWarning ref="changeWarningRef" @refresh="getPreConfig" />
  </div>
</template>

<script>
import { GetPreWarning, DelCustomWarning } from "@/api";

import ChangeWarning from "./dialog/change_warning.vue";

export default {
  name: "custom_warning",
  components: { ChangeWarning },
  data() {
    return {
      userInfo: "",
      // 自定义预警列表
      warnList: [],
    };
  },
  methods: {
    // 获取自定义预警列表
    async getPreConfig() {
      const operatorId = this.$store.getters.id_operator;
      const { ret, data, msg } = await GetPreWarning({
        warn_level: 2,
        id_operator: operatorId,
      });
      if (ret !== 0) {
        return this.$message.error(msg || "获取预警列表失败");
      }
      data.map((i) => {
        i.expand = false;
      });
      this.warnList = data;
    },

    // 编辑预警
    editWarning(i) {
      this.$refs.changeWarningRef.edit(i);
    },

    // 添加预警
    addWarningConfig() {
      const role = this.$store.getters.role;
      if (role === 100) {
        this.$message.warning("请使用运营商账号添加预警!");
        return;
      }
      this.$refs.changeWarningRef.add();
    },

    // 删除自定义预警
    async delPreWarningSystem(item) {
      const result = await this.$confirm(
        "自定义预警删除后不可恢复, 是否确定删除? ",
        "删除提醒",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch(() => "cancel");
      if (result === "cancel") return;
      const { ret, data, msg } = await DelCustomWarning({
        id: item.id,
      });
      if (ret !== 0) {
        return this.$message.error(msg || "删除自定义预警失败!");
      }
      this.$message.success("删除成功");
      this.getPreConfig();
    },
  },

  created() {
    this.getPreConfig();
  },

  filters: {
    // 预警类型格式化
    typeFmt(v) {
      switch (v) {
        case 1:
          return "水箱温度";
        case 2:
          return "回水温度";
        case 3:
          return "水箱水位";
        case 4:
          return "供水电流";
      }
    },
    // 单位格式化
    unitFmt(u) {
      switch (u) {
        case 1:
        case 2:
          return "℃";
        case 3:
          return "％";
        case 4:
          return "A";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom_warning {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: $radius;
  padding: 12px 24px;
}

.add_warning_btn {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $theme;
  cursor: pointer;

  i {
    font-size: 14px;
  }

  span {
    margin-left: 4px;
    font-size: 14px;
  }
}

.warning_item {
  margin-top: 6px;
  border-radius: 4px;
  overflow: hidden;

  .title {
    background-color: #f0f3f8;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 32px;

    .title_font {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #666666;
    }

    .right_box {
      display: flex;
    }

    .edit_box {
      display: flex;
      align-items: center;
      margin-right: 12px;

      i {
        color: #2a47b0;
        margin-right: 6px;
      }

      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2a47b0;
      }
    }

    .del_box {
      display: flex;
      align-items: center;

      i {
        color: #2a47b0;
        margin-right: 6px;
      }

      span {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2a47b0;
      }
    }

    .sanjiao {
      width: 48px;
      text-align: center;
      padding-right: 10px;

      i {
        color: #2a47b0;
      }
    }
  }

  .content_box {
    border: 1px solid #f0f3f8;
    border-top: none;

    .time_box {
      width: 100%;
      display: flex;

      .desc {
        width: 366px;
        background-color: #f7f9fb;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .content {
        flex: 1;

        .line {
          height: 42px;
          display: flex;
          align-items: center;
          padding-left: 60px;

          span {
            margin-right: 16px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }

        .line:nth-child(2n) {
          background-color: #f7f9fb;
        }
      }
    }

    .param_box {
      display: flex;

      .desc {
        flex: 0 0 366px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .content {
        flex: 1;
        background-color: #f7f9fb;

        .line {
          height: 42px;
          display: flex;
          align-items: center;
          padding-left: 60px;

          .item {
            flex: 1;
            display: flex;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;

            .type {
              font-weight: 600;
              margin-right: 24px;
            }

            .range1 {
            }

            .range2 {
              margin-left: 24px;
            }
          }
        }

        .line:nth-child(2n) {
          background-color: #f7f9fb;
        }
      }
    }

    .sys_box {
      display: flex;

      .desc {
        width: 366px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        background-color: #f7f9fb;
        min-height: 42px;
      }

      .content {
        flex: 1;

        .line {
          display: flex;
          align-items: center;

          &:nth-child(2n) {
            background-color: #f7f9fb;
          }

          .name_project {
            width: 200px;
            box-sizing: border-box;
            font-size: 14px;
            font-family: Microsoft YaHei;
            color: #333333;
            padding: 12px 0 12px 60px;
            font-weight: 600;
          }

          .system_box {
            flex: 1;
            display: flex;
            flex-wrap: wrap;

            .name {
              box-sizing: border-box;
              padding: 12px 24px 12px 0;
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
            }
          }
        }
      }
    }

    .push_type {
      display: flex;

      .desc {
        width: 366px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .content {
        flex: 1;
        display: flex;
        align-items: center;
        background-color: #f7f9fb;

        .type {
          height: 42px;
          width: 200px;
          line-height: 42px;
          font-size: 14px;
          color: #333;
          font-family: Microsoft YaHei;

          &:nth-child(1) {
            padding-left: 60px;
          }
        }
      }
    }
  }

  .content_box2 {
    @extend .content_box;
    height: 0;
  }
}

.empty_state {
  width: 100%;
  height: calc(100vh - 280px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 280px;
    height: 240px;
    margin-bottom: 16px;
  }
}
</style>
