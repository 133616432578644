<template>
  <el-dialog
    :title="isEdit ? '修改预警' : '添加预警'"
    :visible.sync="dialogVisible"
    width="800px"
    :close-on-click-modal="false"
    @closed="reset"
  >
    <div class="dialog_wrapper">
      <div class="item_box">
        <span>预警类型：</span>
        <el-input
          value="自定义预警"
          :disabled="true"
        > </el-input>
      </div>

      <div class="item_box">
        <span>预警名称：</span>
        <el-input
          v-model="name"
          maxlength="12"
        > </el-input>
      </div>

      <div class="item_box">
        <span>使用系统：</span>
        <el-tree
          ref="treeRef"
          :data="systemList"
          :props="props"
          node-key="value"
          :default-checked-keys="defaultSelected"
          show-checkbox
        >
        </el-tree>
      </div>

      <div class="item_box">
        <span>时间：</span>
        <el-time-picker
          is-range
          v-model="date"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          value-format="HH:mm:ss"
        >
        </el-time-picker>
      </div>

      <div class="item_box">
        <span></span>
        <div>
          <el-checkbox v-model="weeks.week1">周一</el-checkbox>
          <el-checkbox v-model="weeks.week2">周二</el-checkbox>
          <el-checkbox v-model="weeks.week3">周三</el-checkbox>
          <el-checkbox v-model="weeks.week4">周四</el-checkbox>
          <el-checkbox v-model="weeks.week5">周五</el-checkbox>
          <el-checkbox v-model="weeks.week6">周六</el-checkbox>
          <el-checkbox v-model="weeks.week7">周日</el-checkbox>
        </div>
      </div>

      <div class="item_box">
        <span>预警信息推送方式：</span>
        <div>
          <el-checkbox v-model="pushType.push1">短信推送</el-checkbox>
          <el-checkbox v-model="pushType.push2">APP内推送</el-checkbox>
        </div>
      </div>

      <el-divider></el-divider>

      <div class="param_title">
        <span>设置参数：</span>
        <div
          class="right"
          @click="selectType"
        >
          <i class="el-icon-plus"></i>
          <div>选择参数</div>
        </div>
      </div>

      <div class="params_content_box">
        <div
          class="param_content"
          v-if="types.type1"
        >
          <div class="item">
            <div class="desc">水箱水位</div>
            <div class="content">
              <span class="u1">小于</span>
              <input
                type="text"
                v-model="min1"
                @input="ipt('min1')"
                maxlength="2"
              />
              <span>%</span>

              <div class="u2">大于</div>
              <input
                type="text"
                v-model="max1"
                @input="ipt('max1')"
                maxlength="2"
              />
              <span>%</span>
            </div>
          </div>
          <div class="item">
            <div class="desc">持续时间</div>
            <div class="content">
              <span>持续</span>
              <input
                type="text"
                @input="ipt('duration1')"
                v-model="duration1"
                maxlength="3"
              />
              <span>(min)</span>
            </div>
          </div>
        </div>

        <div
          class="param_content"
          v-if="types.type2"
        >
          <div class="item">
            <div class="desc">水箱温度</div>
            <div class="content">
              <span class="u1">小于</span>
              <input
                type="text"
                v-model="min2"
                @input="ipt('min2')"
                maxlength="2"
              />
              <span>℃</span>

              <span class="u2">大于</span>
              <input
                type="text"
                v-model="max2"
                @input="ipt('max2')"
                maxlength="2"
              />
              <span>℃</span>
            </div>
          </div>
          <div class="item">
            <div class="desc">持续时间</div>
            <div class="content">
              <span>持续</span>
              <input
                type="text"
                v-model="duration2"
                @input="ipt('duration2')"
                maxlength="3"
              />
              <span>(min)</span>
            </div>
          </div>
        </div>

        <div
          class="param_content"
          v-if="types.type3"
        >
          <div class="item">
            <div class="desc">回水温度</div>
            <div class="content">
              <span class="u1">小于</span>
              <input
                type="text"
                v-model="min3"
                @input="ipt('min3')"
                maxlength="2"
              />
              <span>℃</span>
              <span class="u2">大于</span>
              <input
                type="text"
                v-model="max3"
                @input="ipt('max3')"
                maxlength="2"
              />
              <span>℃</span>
            </div>
          </div>
          <div class="item">
            <div class="desc">持续时间</div>
            <div class="content">
              <span>持续</span>
              <input
                type="text"
                v-model="duration3"
                @input="ipt('duration3')"
                maxlength="3"
              />
              <span>(min)</span>
            </div>
          </div>
        </div>

        <div
          class="param_content"
          v-if="types.type4"
        >
          <div class="item">
            <div class="desc">供水电流</div>
            <div class="content">
              <span class="u1">小于</span>
              <input
                type="text"
                v-model="min4"
                @input="ipt2('min4')"
                maxlength="5"
              />
              <span>A</span>
              <span class="u2">大于</span>
              <input
                type="text"
                v-model="max4"
                @input="ipt2('max4')"
                maxlength="5"
              />
              <span>A</span>
            </div>
          </div>
          <div class="item">
            <div class="desc">持续时间</div>
            <div class="content">
              <span>持续</span>
              <input
                type="text"
                v-model="duration4"
                @input="ipt('duration4')"
                maxlength="3"
              />
              <span>(min)</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="empty_img"
        v-if="getTypesSelected === 0"
      >
        <img
          src="../../../../assets/empty_img.png"
          alt=""
        />
      </div>
    </div>

    <!-- 内部嵌套的dialog 弹窗 -->
    <el-dialog
      width="600px"
      title="选择参数"
      :visible.sync="innerVisible"
      append-to-body
    >
      <div class="inner_container">
        <el-row class="row">
          <div class="border_btn">
            <el-checkbox
              v-model="selectAll"
              @change="selectAllChange"
            >{{
              selectAll ? '反选' : '全选'
            }}</el-checkbox>
          </div>
        </el-row>
        <el-row class="row2">
          <el-col :span="12">
            <el-checkbox
              v-model="types.type1"
              @change="checkSelectAll"
            >水箱水位</el-checkbox>
          </el-col>
          <el-col :span="12">
            <el-checkbox
              v-model="types.type2"
              @change="checkSelectAll"
            >水箱温度</el-checkbox>
          </el-col>
        </el-row>
        <el-row class="row2">
          <el-col :span="12">
            <el-checkbox
              v-model="types.type3"
              @change="checkSelectAll"
            >回水温度</el-checkbox>
          </el-col>
          <el-col :span="12">
            <el-checkbox
              v-model="types.type4"
              @change="checkSelectAll"
            >供水电流</el-checkbox>
          </el-col>
        </el-row>
      </div>

      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button
          type="primary"
          @click="innerVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button
        type="primary"
        @click="confirm"
      >确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { GetProjectList, AddCustomWarn, UpdateWarning2 } from '@/api'

export default {
  data() {
    return {
      // 是否显示弹窗
      dialogVisible: false,
      // 添加还是修改
      isEdit: false,
      // 修改-预警id
      id: '',
      // 预警名称
      name: '自定义预警',
      // 修改-预警所属运营商
      id_operator: '',
      // 默认选中
      defaultSelected: [],
      // 系统列表
      systemList: [],
      // 树形控件 参数
      props: {
        label: 'label',
        children: 'children'
      },
      // 预警时间
      date: null,
      // 星期选择
      weeks: {
        week1: true,
        week2: true,
        week3: true,
        week4: true,
        week5: true,
        week6: true,
        week7: true
      },
      // 推送方式
      pushType: {
        push1: true,
        push2: true
      },
      // 内层的dialog
      innerVisible: false,
      // 类型
      types: {
        type1: false,
        type2: false,
        type3: false,
        type4: false
      },
      // 是否全部选中
      selectAll: false,
      // 四个自定义参数 12个 v-model绑定的值
      // 相同的命名 易于后面合并参数
      // 第一组参数
      min1: '',
      max1: '',
      duration1: '',
      // 第二组参数
      min2: '',
      max2: '',
      duration2: '',
      // 第三组参数
      min3: '',
      max3: '',
      duration3: '',
      // 第四组参数
      min4: '',
      max4: '',
      duration4: ''
    }
  },
  methods: {
    // 只能输入数字
    ipt(key) {
      this[key] = this[key].replace(/[^\d]/g, '')
    },

    // 能同时输入数字和点
    ipt2(key) {
      this[key] = this[key].replace(/[^\d.]/g, '')
    },

    // 弹窗关闭 重置
    reset() {
      this.name = '自定义预警'
      this.systemList = []
      this.date = null
      this.weeks.week1 = true
      this.weeks.week2 = true
      this.weeks.week3 = true
      this.weeks.week4 = true
      this.weeks.week5 = true
      this.weeks.week6 = true
      this.weeks.week7 = true
      this.types.type1 = false
      this.types.type2 = false
      this.types.type3 = false
      this.types.type4 = false
      this.pushType.push1 = true
      this.pushType.push2 = true
      this.selectAll = false
      this.min1 = this.max1 = this.duration1 = ''
      this.min2 = this.max2 = this.duration2 = ''
      this.min3 = this.max3 = this.duration3 = ''
      this.min4 = this.max4 = this.duration4 = ''
    },

    // 添加自定义预警
    add() {
      this.id_operator = this.$store.getters.id_operator
      this.isEdit = false
      this.getSystemList()
      this.checkSelectAll()
      this.dialogVisible = true
    },

    // 修改预警
    edit(info) {
      this.id_operator = info.id_operator
      this.id = info.id
      this.isEdit = true
      this.dialogVisible = true
      this.getSystemList()
      this.$nextTick(() => {
        // 回显内容
        // 名称
        this.name = info.name
        // 使用系统回显
        // 日期
        this.date = [
          info.time_start.padStart(8, '0'),
          info.time_end.padStart(8, '0')
        ]
        // 渲染星期
        const weeks = [0, 1, 2, 3, 4, 5, 6]
        weeks.map(i => {
          if (
            (info.weekday & Math.pow(2, weeks[i])) ===
            Math.pow(2, weeks[i])
          ) {
            this.weeks['week' + (i + 1)] = true
          } else {
            this.weeks['week' + (i + 1)] = false
          }
        })
        // 渲染推送方式
        if ((info.push_type & 1) === 1) {
          this.pushType.push1 = true
        } else {
          this.pushType.push1 = false
        }
        if ((info.push_type & 2) === 2) {
          this.pushType.push2 = true
        } else {
          this.pushType.push2 = false
        }
        // 渲染参数
        info.warn_array.map(i => {
          this.types['type' + i.type] = true
          this['min' + i.type] = i.min
          this['max' + i.type] = i.max
          this['duration' + i.type] = i.time_duration
        })
        if (info.warn_array.length === 4) {
          this.selectAll = true
        } else {
          this.selectAll = false
        }
        // 回显树
        const defaultSelected = []
        info.org_array.map(i => {
          i.device_array.map(j => {
            defaultSelected.push(j.id_device)
          })
        })
        this.defaultSelected = defaultSelected
      })
    },

    // 确定全选/反选状态
    checkSelectAll() {
      let len = 0
      Object.keys(this.types).map(k => {
        if (this.types[k]) {
          len++
        }
      })
      if (len === 4) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    },

    // 获取系统列表
    async getSystemList() {
      const { ret, data, msg } = await GetProjectList()
      if (ret !== 0) {
        return this.$message.error(msg || '获取系统列表失败!')
      }
      const result = []
      data.data.map(i => {
        const p = {
          label: i.name_project,
          value: -i.id,
          level: 1,
          children: []
        }
        if (i.device_list && i.device_list.length) {
          const children = []
          i.device_list.map(j => {
            children.push({
              label: j.name,
              value: j.id_device,
              level: 2
            })
          })
          p.children = children
        }

        result.push(p)
      })
      this.systemList = result
    },

    // 选择类型
    selectType() {
      this.innerVisible = true
    },

    // 全选发生变化
    selectAllChange(flag) {
      Object.keys(this.types).map(i => {
        this.types[i] = flag
      })
    },

    // 确定
    confirm() {
      // 校验名字
      if (!this.name.trim()) {
        return this.$message.warning('请输入预警名称')
      }
      // 校验选中的系统
      const deviceList = []
      const selects = this.$refs.treeRef.getCheckedNodes()
      selects.map(i => {
        if (i.level === 2) {
          deviceList.push(i.value)
        }
      })
      if (!deviceList.length) {
        this.$message.warning('未选中任何系统')
        return
      }

      // 时间校验
      if (!this.date || !this.date[0] || !this.date[1]) {
        this.$message.warning('请选择时间')
        return
      }
      if (this.date[0] === this.date[1]) {
        return this.$message.warning('时间不能相同')
      }

      // 选中的星期校验
      const selectWeeks = []
      Object.keys(this.weeks).map(i => {
        if (this.weeks[i]) {
          selectWeeks.push(i)
        }
      })
      if (!selectWeeks.length) {
        this.$message.warning('请选择星期')
        return
      }

      // 选中的推送方式
      const selectPushes = []
      Object.keys(this.pushType).map(k => {
        if (this.pushType[k]) {
          selectPushes.push(k)
        }
      })
      if (!selectPushes.length) {
        this.$message.warning('请选择推送方式')
        return
      }

      // 异常类型校验
      const selectTypes = []
      Object.keys(this.types).map(k => {
        if (this.types[k]) {
          selectTypes.push(k)
        }
      })
      if (!selectTypes.length) {
        return this.$message.warning('请至少设置一种预警参数')
      }
      // 判断参数是不是正确的
      if (this.types.type1) {
        if (Number(this.max1) <= Number(this.min1)) {
          this.$message.warning('水箱温度范围错误')
          return
        }
        if (Number(this.duration1) <= 0) {
          this.$message.warning('水箱温度持续时间错误')
          return
        }
      }
      if (this.types.type2) {
        if (Number(this.max2) <= Number(this.min2)) {
          this.$message.warning('回水温度范围错误')
          return
        }
        if (Number(this.duration2) <= 0) {
          this.$message.warning('回水温度持续时间错误')
          return
        }
      }
      if (this.types.type3) {
        if (Number(this.max3) <= Number(this.min3)) {
          this.$message.warning('水箱水位范围错误')
          return
        }
        if (Number(this.duration3) <= 0) {
          this.$message.warning('水箱水位持续时间错误')
          return
        }
      }
      if (this.types.type4) {
        if (isNaN(Number(this.min4)) || isNaN(Number(this.max4))) {
          return this.$message.warning('请检查供水电流输入')
        }
        if (Number(this.max4) <= Number(this.min4)) {
          return this.$message.warning('供水电流范围错误')
        }
        if (Number(this.duration4) <= 0) {
          this.$message.warning('供水电流持续时间错误')
          return
        }
      }
      if (this.isEdit) {
        this.saveEdit()
      } else {
        this.addWarning()
      }
    },

    // 添加预警
    async addWarning() {
      const params = {
        name: this.name.trim(),
        warn_level: 2,
        time_start: this.date[0],
        time_end: this.date[1],
        id_operator: this.id_operator
      }
      // weekday
      let sum = 0
      const baseSystem = [0, 1, 2, 4, 8, 16, 32, 64]
      Object.keys(this.weeks).map(i => {
        if (this.weeks[i]) {
          const index = i.substring(4)
          sum += baseSystem[index / 1]
        }
      })
      params.weekday = sum
      // 推送方式
      let pushSum = 0
      Object.keys(this.pushType).map(k => {
        if (this.pushType[k]) {
          const index = k.substring(4)
          pushSum += baseSystem[index / 1]
        }
      })
      params.push_type = pushSum
      // device_array
      const deviceList = []
      const selects = this.$refs.treeRef.getCheckedNodes()
      selects.map(i => {
        if (i.level === 2) {
          deviceList.push(i.value)
        }
      })
      params.device_array = deviceList
      // warn_array
      const warnArray = []
      Object.keys(this.types).map(i => {
        if (this.types[i]) {
          const index = i.substring(4)
          const c = {}
          c.min = this['min' + index] / 1
          c.max = this['max' + index] / 1
          c.time_duration = this['duration' + index] / 1
          c.type = index / 1
          warnArray.push(c)
        }
      })
      params.warn_array = warnArray
      const { ret, data, msg } = await AddCustomWarn(params)
      if (ret !== 0) {
        return this.$message.error(msg || '添加自定义预警失败!')
      }
      this.$message.success('添加成功')
      this.$emit('refresh')
      this.dialogVisible = false
    },

    // 保存修改的内容
    async saveEdit() {
      const params = {
        name: this.name.trim(),
        warn_level: 2,
        time_start: this.date[0],
        time_end: this.date[1],
        id_operator: this.id_operator,
        id_warn: this.id
      }
      // weekday
      let sum = 0
      Object.keys(this.weeks).map(i => {
        const baseSystem = [0, 1, 2, 4, 8, 16, 32, 64]
        if (this.weeks[i]) {
          const index = i.substring(4)
          sum += baseSystem[index / 1]
        }
      })
      params.weekday = sum
      // pushType
      let pushSum = 0
      Object.keys(this.pushType).map(k => {
        if (this.pushType[k]) {
          const index = k.substring(4) / 1
          pushSum += index
        }
      })
      params.push_type = pushSum
      // device_array
      const deviceList = []
      const selects = this.$refs.treeRef.getCheckedNodes()
      selects.map(i => {
        if (i.level === 2) {
          deviceList.push(i.value)
        }
      })
      params.device_array = deviceList
      // warn_array
      const warnArray = []
      Object.keys(this.types).map(i => {
        if (this.types[i]) {
          const index = i.substring(4)
          const c = {}
          c.min = this['min' + index] / 1
          c.max = this['max' + index] / 1
          c.time_duration = this['duration' + index] / 1
          c.type = index / 1
          warnArray.push(c)
        }
      })
      params.warn_array = warnArray
      const { ret, data, msg } = await UpdateWarning2(params)
      if (ret !== 0) {
        return this.$message.error(msg || '更新自定义预警失败!')
      }
      this.$message.success('更新成功')
      this.$emit('refresh')
      this.dialogVisible = false
    }
  },

  computed: {
    // 计算选中的参数类型的长度, 判断是否显示缺省页
    getTypesSelected() {
      let len = 0
      Object.keys(this.types).map(i => {
        if (this.types[i]) {
          len++
        }
      })
      return len
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog_wrapper {
  padding: 0 24px;

  .item_box {
    display: flex;
    align-items: center;
    margin-top: 16px;

    span {
      width: 140px;
      text-align: right;
      padding-right: 10px;
    }

    .el-input {
      width: 160px;
    }
  }

  .param_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;

    span {
      width: 120px;
      text-align: right;
      padding-right: 10px;
    }

    .right {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: #2a47b0;

      i {
        margin-right: 4px;
        transform: translateY(1px);
      }
    }
  }

  .params_content_box {
    .param_content {
      .item {
        margin: 16px 0;
        display: flex;

        .desc {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 160px;
        }

        .content {
          display: flex;
          align-items: center;
          padding-left: 120px;

          span {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }

          .u2 {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-left: 12px;
          }

          input {
            width: 64px;
            height: 32px;
            border: 1px solid #999999;
            border-radius: 4px;
            outline: none;
            margin: 0 4px;
            text-align: center;
          }
        }
      }
    }

    .param_content:nth-child(n + 2) {
      border-top: 1px solid #dcdfe6;
    }
  }

  .empty_img {
    height: 166px;
    text-align: center;

    img {
      width: 196px;
      height: 166px;
    }
  }
}

.inner_container {
  padding: 0 24px;

  .row {
    margin-top: 24px;

    .border_btn {
      width: 90px;
      height: 36px;
      border-radius: 18px;
      border: 1px solid #2a47b0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .row2 {
    margin-top: 24px;

    .el-col {
      padding-left: 36px;
    }
  }
}
</style>
